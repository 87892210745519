<div class="container-fluid p-0">
  <div class="row g-0">
    <div class="col-xl-5 d-none d-md-block">
      <div class="auth-full-bg pt-lg-5 p-4">
        <div class="w-100">
          <div class="bg-overlay"></div>
          <div class="d-flex h-100 flex-column">
            <div class="p-4 mt-auto">
              <div class="row justify-content-center">
                <div class="col-lg-7">
                  <div class="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end col -->

    <div class="col-xl-7">
      <div class="auth-full-page-content p-md-5 p-4 affiliate-form-container">
        <div class="w-100">
          <div class="d-flex flex-column h-100">
            <div class="my-auto">
              <div style="text-align: left; margin-bottom: 4rem">
                <img
                  src="assets/images/cadland-logo.png"
                  alt=""
                  class="auth-logo-dark logo"
                />
              </div>
              <div>
                <h5 class="header-text">Welcome Back!</h5>
                <p class="sub-header-text">
                  Log-in to continue to Cadlands Affiliate Account.
                </p>

                <p
                  *ngIf="showErr"
                  class="text-danger"
                  style="text-align: center"
                >
                  {{ error }}
                </p>
              </div>

              <div class="mt-4">
                <form
                  class="justify-content: center"
                  [formGroup]="loginForm"
                  (ngSubmit)="onSubmit()"
                >
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        formControlName="email"
                        [ngClass]="{
                          'is-invalid': submitted && f.email.errors
                        }"
                        placeholder="Enter email"
                      />
                      <div
                        *ngIf="submitted && f.email.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.email.errors.required">
                          Email is required
                        </div>
                        <div *ngIf="f.email.errors.email">
                          Email must be a valid email address
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <div class="float-end">
                        <a routerLink="/account/recoverpwd-2" class="text-muted"
                          >Forgot password?</a
                        >
                      </div>
                      <label for="userpassword">Password</label>
                      <div class="input-group auth-pass-inputgroup">
                        <input
                          [type]="fieldTextType ? 'text' : 'password'"
                          class="form-control"
                          formControlName="password"
                          [ngClass]="{
                            'is-invalid': submitted && f.password.errors
                          }"
                          placeholder="Enter password"
                          aria-label="Password"
                          aria-describedby="password-addon"
                        />
                        <button
                          class="btn btn-light"
                          type="button"
                          id="password-addon"
                        >
                          <i
                            class="mdi"
                            [ngClass]="{
                              'mdi-eye-off-outline': !fieldTextType,
                              'mdi-eye-outline': fieldTextType
                            }"
                            (click)="toggleFieldTextType()"
                          ></i>
                        </button>
                        <div
                          *ngIf="submitted && f.password.errors"
                          class="invalid-feedback"
                        >
                          <span *ngIf="f.password.errors.required"
                            >Password is required</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="remember-check"
                    />
                    <label class="form-check-label" for="remember-check">
                      Remember me
                    </label>
                  </div>

                  <div class="login-button" style="margin-top: 4rem;">
                    <button
                      *ngIf="isLoggingIn"
                      class="btn"
                      style="
                        background-color: #510000;
                        color: #ffff;
                        font-size: 1rem;
                        padding: 1rem 4.5rem;
                        border-radius: 60px;
                      "
                      type="submit"
                    >
                      Logging In <i class="bx bx-loader bx-spin"></i>
                    </button>
                    <button
                      *ngIf="!isLoggingIn"
                      class="btn"
                      style="
                        background-color: #510000;
                        color: #ffff;
                        font-size: 1rem;
                        padding: 1rem 4.5rem;
                        border-radius: 60px;
                      "
                      type="submit"
                    >
                      Log In
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div class="mt-4 mt-md-5 text-left">
              <p class="mb-0">© {{ year }} Cadlands.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</div>
<!-- end container-fluid -->
